import React from 'react'
import posed from 'react-pose'

const CardWrap = posed.div({
  unselected: {border: '6px solid #000', borderRadius: 13},
  notSelected: {border: '6px solid #000', borderRadius: 13, opacity: 0.5},
  selected: {border: '6px solid #fff', borderRadius: 17, opacity: 1}
})

const VoteCard = ({label, image, result, showResult, onClick, selected}) => {
  let poseClass = selected === null ? 'unselected' : (selected ? 'selected' : 'notSelected')
  return <div className={`vote-card`} 
    onClick={(onClick && onClick.bind(null, label)) || undefined}>
    <CardWrap pose={poseClass}>
      <div className={`vote-image-wrap`}>
        <img src={image} className={`vote-image ${poseClass}`} alt='option' />
        {showResult && <div className='result'>{result || '0%'}</div>}
      </div> 
      <div className='vote-label'>{label}</div>
    </CardWrap>
  </div>
}

export default VoteCard