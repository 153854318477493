import React from 'react'

import Api from '../lib/api'
import VoteCard from './VoteCard'
import obj from '../shared/cards'
import solveImg from '../img/tap-to-solve@2x.png'
import thanksImg from '../img/thanks@2x.png'

const headers = {
  voted: {src: thanksImg, width: 308, height: 55},
  vote: {src: solveImg, width: 308, height: 61}
}

const { cards } = obj

const ISDEV = process.env.NODE_ENV !== 'production'

function storedSelectedIndex() {
  const selectedIndex = localStorage.getItem('selectedIndex')
  if (selectedIndex) return Number(selectedIndex)
}

export default class VoteHome extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      voted: this.hasVoted(),
      selectedIndex: storedSelectedIndex(),
      results: null
    }
  }

  render() {
    const { voted, results, selectedIndex } = this.state
    const title = voted ? headers.voted : headers.vote
    return (
      <div>
        {ISDEV && this.renderDev()}
        <h1><img {...title} /></h1>

        <div className='vote-grid' pose='visible'>
          {cards.map((opt, i) => (
            <VoteCard key={i}
              selected={typeof(selectedIndex) === 'undefined' ? null : selectedIndex === i}
              label={opt.label}
              image={opt.image}
              result={results && results.totals[opt.label]}
              showResult={!!results}
              onClick={!voted && this.clickedCard.bind(null, i)} />
          ))}
        </div>
      </div>
    )
  }

  renderDev() {
    return (
      <div style={{backgroundColor: '#ccc', padding: 10}}>
        <button onClick={this.resetVoted} style={{fontSize: 14, fontWeight: 'bold'}}>Reset</button>
        <button onClick={this.showResults} style={{fontSize: 14, fontWeight: 'bold'}}>Show Results</button>
      </div>
    )
  }

  showResults = () => {
    window.location.href = '/results.html'
  }

  clickedCard = (i, label, e) => {
    this.setState({selectedIndex: i})
    this.submitVote(label)
  }

  submitVote(label) {
    Api.vote({label: label}).then(res => {
      this.markVoted(label)
    })
  }

  resetVoted = () => {
    localStorage.removeItem('voted')
    localStorage.removeItem('selectedIndex')
    this.setState({voted: false, results: null, selectedIndex: null})
  }

  hasVoted() {
    return localStorage.getItem('voted')
  }

  markVoted(label) {
    localStorage.setItem('voted', true)
    localStorage.setItem('selectedIndex', obj.cards.findIndex(card => card.label === label))
    this.setState({voted: true})
  }
}