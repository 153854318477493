import request from 'superagent'

const HOST = (process.env.NODE_ENV === 'production') ? "https://solve-api.upfront.com" : '';

function url(path) {
  return `${HOST}${path}`
}

const Api = {
  triggerAnimation: () => Api.post('/api/trigger_animation'),
  getResults: () => Api.get('/api/results'),
  getAnimationResults: () => Api.get('/api/results_animation'),
  vote: (data) => Api.post('/api/vote', data),

  get: (path) => request.get(url(path)),
  post: (path, data) => request.post(url(path)).send(data).type('json')
}

export default Api